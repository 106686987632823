@font-face { font-family: "ProximaSoft"; src: local("ProximaSoft"), url("./fonts/ProximaSoft/ProximaSoft-Thin.ttf") format("truetype"); font-weight: 100; }
@font-face { font-family: "ProximaSoft"; src: local("ProximaSoft"), url("./fonts/ProximaSoft/ProximaSoft-Light.ttf") format("truetype"); font-weight: 200; }
@font-face { font-family: "ProximaSoft"; src: local("ProximaSoft"), url("./fonts/ProximaSoft/ProximaSoft-Regular.ttf") format("truetype"); font-weight: 300; }
@font-face { font-family: "ProximaSoft"; src: local("ProximaSoft"), url("./fonts/ProximaSoft/ProximaSoft-Medium.ttf") format("truetype"); font-weight: 400; }
@font-face { font-family: "ProximaSoft"; src: local("ProximaSoft"), url("./fonts/ProximaSoft/ProximaSoft-Semibold.ttf") format("truetype"); font-weight: 500; }
@font-face { font-family: "ProximaSoft"; src: local("ProximaSoft"), url("./fonts/ProximaSoft/ProximaSoft-Bold.ttf") format("truetype"); font-weight: 600; }
@font-face { font-family: "ProximaSoft"; src: local("ProximaSoft"), url("./fonts/ProximaSoft/ProximaSoft-Extrabold.ttf") format("truetype"); font-weight: 700; }
@font-face { font-family: "ProximaSoft"; src: local("ProximaSoft"), url("./fonts/ProximaSoft/ProximaSoft-Black.ttf") format("truetype"); font-weight: 800; }

body {
  margin: 0;
  padding: 0;
  background-color: white;
  font-family: "ProximaSoft";
  letter-spacing: 0.1px;
}

p {
  margin: 0;
}

.cog2ct > img {
  width: 92%;
  aspect-ratio: 1;
}

.rel {
  position: absolute;
}

.none {
  display: none;
}

a {
  text-decoration: none;
  cursor: pointer;
}

.lnk > img {
  width: 200px;
  aspect-ratio: 1;
}

.lnk > div > img {
  height: 64px;
  cursor: pointer;
}

.bckdrp {
  position: fixed;
  padding:0;
  margin:0;
  display: flex;
  flex-direction: column;
  justify-content: end;
  top:0;
  left:0;

  width: 100%;
  height: 100%;
  background:rgba(0,0,0,0.24);
  z-index: 100;
}

.cks {
  background-color: white;
  border-top: 3px solid #E4E4E6;
}

.cksbtn {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

@media only screen and (min-width: 1080px) {
  .cksbtn {
    align-items: flex-start;
  }
}

.brrd24 {
  border-radius: 24px;
}

/* LEGAL */

.services-table {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  line-height: 20px;
  font-size: 14px;
}

@media only screen and (min-width: 768px) {
  .services-table {
    line-height: 24px;
    font-size: 16px;
  }
}

.services-table-heading {
  font-weight: 600;
}

.services-table-cell a {
  color: rgba(56,112,255,1);
}

.services-table-left {
  float: left;
  width: 20%;
  box-sizing: border-box;
  padding-right: 12px;
}

.services-table-center {
  float: left;
  width: 40%;
  box-sizing: border-box;
  padding-right: 12px;
  overflow: hidden;
}

.services-table-right {
  float: left;
  width: 40%;
  box-sizing: border-box;
  padding-right: 12px;
}

.divider-primary {
  height: 2px;
  width: 100%;
  border-radius: 1px;
  background-color: rgba(130,132,138,0.32);
  margin: 16px 0;
}

.divider-secondary {
  height: 2px;
  width: 100%;
  border-radius: 1px;
  background-color: rgba(130,132,138,0.16);
  margin: 16px 0;
}

/* TEXTFIELD */

.txtf input {
  box-sizing: border-box;
  width: 100%;
  height: 52px;
  background: #F7F8F9;
  border: 2px solid #DEDFE1;
  color: black;
  border-radius: 12px;
  padding: 0 16px;
  font-size: 16px;
}

.pasres {
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}

.aist {
  align-items: flex-start;
}

@media only screen and (min-width: 420px) {
  .pasres {
    width: 420px;
  }
}

/* NIDO */

.nido {
  position: relative;
  top: 16px;
}

.nido > img, .nido > div {
  position: absolute;
}

.nidoh {
  position: absolute;
  width: 200px;
  left: calc(50% - 100px);
  top: 28px;
}

.nidol1s {
  display: flex;
  left: calc(50% - 100px);
  top: 210px;
}

.nidol1 {
  width: 100px;
}

.nidol2s {
  display: flex;
  left: calc(50% - 152px);
  top: 210px;
}

.nidol2 {
  width: 152px;
}

.y180 {
  transform: rotate3d(0, 1, 0, 180deg);
}

.nidoeyes {
  display: flex;
  gap: 36px;
  position: absolute;
  left: calc(50% - 54px);;
  top: 92px;
}

.nidoeye {
  background-color: white;
  width: 36px;
  height: 50px;
  border-radius: 18px;
  position: relative;
  overflow: hidden;
}

.nidopupil {
  background-color: #282828;
  width: 17px;
  height: 26px;
  border-radius: 18px;
  position: absolute;
  left: calc(50% - 8.5px);
  top: calc(50% - 13px);
}

.nidoeyelid {
  background-color: #F8324C;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  position: absolute;
  left: calc(18px - 50px);
  top: calc(25px - 50px);
}

.nidomouth {
  background-color: #D4223A;
  width: 41px;
  height: 8px;
  border-radius: 6px;
  position: absolute;
  left: calc(50% - 20.1px);
  top: 166px;
}

.nidoshdw {
  width: 288px;
  height: 96px;
  background-color: #2696D7;
  left: calc(50% - 144px);
  top: 232px;
  border-radius: 100%;
  z-index: -10;
}

@media only screen and (min-width: 1080px) {
  .nido {
    top: 4px;
  }
}

/* COGNIDO */

.cog1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.cog1b {
  text-align: center;
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.cog1t {
  float: left;
  width: 100%;
  aspect-ratio: 1;
}

.cog1as {
  position: absolute;
}

.cog1as img {
  height: 56px;
}

.cog1bb {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.cog2c {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.cog2ct {
  float: left;
  width: 100%;
  aspect-ratio: 1;
  position: relative;

}

.cog2ct2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.cog2ct2 > div {
  position: absolute;
}

.cog2ddivs {
  gap: 80px;
}

.cog2ddiv {
  border-radius: 6px;
}

.cog2ct3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.cog2ct3 div {
  position: absolute;
}

.cog2ct3 img {
  height: 56px;
}

.cog2rt {
  width: 48%;
  height: 72%;
  background-color: transparent;
  position: absolute;
  left: calc(26% - 12px);
  top: calc(14% - 12px);
  border: 12px solid #E4E4E6;
  border-radius: 24px;
  letter-spacing: 0.8px;
}

.cog2trgt1 {
  width: 24%;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: #F8324C;
  position: absolute;
  left: 38%;
  top: 38%;
}

.cog2trgt2 {
  width: 66%;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  left: 16.6%;
  top: 16.6%;
}

.cog2trgt3 {
  width: 50%;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: #F8324C;
  position: absolute;
  left: 25%;
  top: 25%;
}

.cog2cb {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 12px;
}

.cog2cb div {
  letter-spacing: 0.8px;
}

.cog3 {
  text-align: center;
  margin-top: 64px;
}

.cog3sct {overflow-x: clip;}

.cog3btn {
  margin-top: 32px;
  width: 100%;
}

.cog3 img {
  height: 68px;
  cursor: pointer;
}

.cog3g div {
  position: absolute;
}

.cog3g img {
  height: 52px;
  aspect-ratio: 1;
  transform: scale(1.1);
}

.cog3g {
  width: 100%;
  aspect-ratio: 1.1;
  position: relative;
}

.cog3trgt1 {
  width: 80px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: #F8324C;
  position: absolute;
}

.cog4t {
  text-align: center;
}

.cog4h {
  display: flex;
  flex-direction: column-reverse;
  gap: 24px;
  align-items: center;
}

.cog4h img {
  width: 48px;
  height: 48px;
}

.cog4bc {
  height: 80px;
  float: left;
  width: 100%;
  margin-top: 24px;
  box-sizing: border-box;
  padding: 0 24px;
  border-radius: 16px;
  font-weight: 600;
  font-size: 17px;
  letter-spacing: 0.6px;
}

.cog4bc img {
  width: 40px;
  height: 40px;
}

.cog6b {
  width: 100%;
}

.cog6bc {
  width: 100%;
  border: 3px solid #E4E4E6;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 16px 20px;
}

.cog6bct {
  width: 100%;
}

.cog6bct img {
  width: 28px;
  height: 28px;
}

.cog6b {
  margin-top: 24px;
  margin-bottom: 64px;
}

@media only screen and (min-width: 1080px) {
  .cog1 {
    flex-direction: row;
    gap: 64px;
  }

  .cog3g img {
    height: 56px
  }

  .cog3btn {
    width: 40%;
  }

  .cog1b {
    text-align: left;
    flex-direction: row;
    width: unset;
    box-sizing: border-box;
    padding: 0 80px 0 0;
  }

  .cog1t {
    width: 40%;
  }

  .cog1bb {
    flex-direction: row;
    gap: 24px;
  }

  .cog2c {
    display: flex;
    flex-direction: row-reverse;
    gap: 32px;
    align-items: center;
  }

  .cog2cl {
    flex-direction: row;
  }

  .cog2ct {
    width: 512px;
  }

  .cog2cb {
    width: unset;
    align-items: flex-start;
    text-align: left;
    width: 100%;
  }

  .cog3g {
    aspect-ratio: 4;
  }

  .cog3g img {
    transform: scale(1.0);
    transform: translateY(40px);
  }

  .cog3trgt1 {
    transform: translateY(1000px);
  }

  .cog4t {
    width: 60%;
  }

  .cog4b {
    width: 80%;
  }

  .cog6b {
    width: 60%;
  }

  .cog4bc {
    width: calc(50% - 12px);
  }

  .cog4bcr {
    margin-left: 24px;
  }

  .cog4h {
    flex-direction: row;
    justify-content: center;
    gap: 16px;
  }

  .cog4h img {
    width: 36px;
    height: 36px
  }
}

/* BUTTON */

.btnnb {
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  letter-spacing: 0.8px;
  width: 100%;
}

.btns, .btn {
  position: relative;
  height: 44px;
}

.btn {
  width: 100%;
  height: 52px;
}

.btnsb, .btnb {
  position: absolute;
  top: 4px;
  height: 40px;
  width: 100%;
  z-index: 0;
  border-radius: 12px;
}

.btnb {
  height: 47px;
  top: 5px;
  border-radius: 14px;
}

.btnst, .btnt {
  position: relative;
  height: 40px;
  top: 0;
  box-sizing: border-box;
  padding: 0 24px;
  z-index: 1;
  border-radius: 12px;
  letter-spacing: 1.2px;
  cursor: pointer;
}

.btnt {
  height: 47px;
  border-radius: 14px;
}

@media only screen and (min-width: 1080px) {
  .btnnb {
    padding: 0 24px;
    width: unset;
  }

  .btn {
    width: unset;
  }

  .btnt {
    padding: 0 64px;
  }
}

/* FONT SIZE */

.fnts12 {
  font-size: 12px;
  line-height: 18px;
}

.fnts14 {
  font-size: 14px;
  line-height: 20px;
}

.fnts15 {
  font-size: 15px;
}

.fnts16 {
  font-size: 16px;
  line-height: 24px;
}

.fnts17 {
  font-size: 17px;
  line-height: 26px;
}

.fnts18 {
  font-size: 18px;
  line-height: 28px;
}

.fnts19 {
  font-size: 19px;
  line-height: 24px;
}

.fnts20 {
  font-size: 20px;
  line-height: 26px;
}

.fnts22 {
  font-size: 22px;
}

.fnts24 {
  font-size: 24px;
  line-height: 36px;
}

.fnts28 {
  font-size: 28px;
  line-height: 40px;
}

.fnts32 {
  font-size: 32px;
  line-height: 44px;
}

.fnts40 {
  font-size: 40px;
  line-height: 52px;
}

.fnts48 {
  font-size: 48px;
}

/* FONT WEIGHT */

.fntw100 {
  font-weight: 100;
}

.fntw200 {
  font-weight: 200;
}

.fntw300 {
  font-weight: 300;
}

.fntw400 {
  font-weight: 400;
}

.fntw500 {
  font-weight: 500;
}

.fntw600 {
  font-weight: 600;
}

.fntw700 {
  font-weight: 700;
}

.fntw800 {
  font-weight: 800;
}

/* GAP */

.gp4 {
  gap: 4px;
}

.gp8 {
  gap: 8px;
}

.gp10 {
  gap: 10px;
}

.gp12 {
  gap: 12px;
}

.gp16 {
  gap: 16px;
}

.gp20 {
  gap: 20px;
}

.gp24 {
  gap: 24px;
}

.gp28 {
  gap: 28px;
}

.gp32 {
  gap: 32px;
}

.gp48 {
  gap: 48px;
}

.gp64 {
  gap: 64px;
}

/* PADDING */

.pdt1 {
  padding-top: 1px;
}

.pd9648 {
  padding: 96px 48px;
}

.pd6432 {
  padding: 64px 32px;
}

.pd4824 {
  padding: 48px 24px;
}

.pd1224 {
  padding: 12px 24px;
}

.pd3224 {
  padding: 32px 24px;
}

.pd6424 {
  padding: 64px 24px;
}

.pd9624 {
  padding: 96px 24px;
}

.appbtns img {
  max-width: calc((100% - 8px) / 2);
  max-height: 48px;
}

/* LOGOS */

.lghi {
  width: 44px;
  height: 44px;
}

.lght {
  height: 28px;
  display: none;
}

@media only screen and (min-width: 768px) {
  .lght {
    display: block;
  }
}

/* LAYOUT */

.sct {
  width: 100%;
  position: relative;
}

.c {
  width: 100%;
  box-sizing: border-box;
}

.hc {
  width: 100%;
  box-sizing: border-box;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 100;
}

.divi {
  width: 100%;
  height: 2px;
  border-radius: 1px;
  background-color: #A9BBCC;
}

@media only screen and (min-width: 420px) {
  .c {
    width: 420px;
  }
}

@media only screen and (min-width: 1080px) {
  .c, .hc {
    width: 1080px;
  }
}

/* FOOTER */

.ftl {
  float: left;
  width: 100%;
  padding-bottom: 32px;
}

.ftwav, .plswav {
  width: 100%;
  overflow: hidden;
}

.ftwav img {
  max-width: 200%;
  height: auto;
  transform: translateY(4px);
}

.plswav img {
  max-width: 480%;
  height: auto;
  transform: translateY(5px);
}

.fcw {
  cursor: pointer;
}

@media only screen and (min-width: 420px) {
  .plswav img {
    max-width: 320%;
  }
}

@media only screen and (min-width: 768px) {
  .ftwav img {
    max-width: 140%;
  }

  .plswav img {
    max-width: 240%;
  }
}

@media only screen and (min-width: 1080px) {
  .ftwav img {
    max-width: 100%;
  }

  .plswav img {
    max-width: 180%;
  }

  .ftl {
    width: 206.4px;
    padding-bottom: 0;
  }
}

@media only screen and (min-width: 1520px) {
  .plswav img {
    max-width: 140%;
  }
}

/* FOREGROUND */

.fclb {
  color: #44B0EE;
}

.fcdb {
  color: #184E8C;
}

.fcw {
  color: white;
}

.fclg {
  color: #A9BBCC;
}

.fcdg {
  color: #606f7e;
}

.fcb {
  color: #3e3c50;
}

.fcbl {
  color: #4A9CFB
}

.bblue {
  color: #4A9CFB
}

.fcg {
  color: #3CD898;
}

/* BACKGROUND */

.blb {
  background-color: #44B0EE;
}

.bdb {
  background-color: #184E8C;
}

.bg {
  background-color: #3CD898;
}

.bw {
  background-color: #FFFFFF;
}

.bdbsec {
  background-color: #8AB7EB;
}

.blbsec {
  background-color: #B3DFFF;
}

.bgsec {
  background-color: #9FE9CB;
}

.blbter {
  background-color: #2591CF;
}

.bfll {
  background-color: rgba(120, 122, 128, 0.16)
}

.nmbrc {
  background-color: rgba(120, 122, 128, 0.16);
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}

/* FLEX */

.fxc {
  display: flex;
  flex-direction: column;
}

.fxr {
  display: flex;
  flex-direction: row;
}

.aic {
  align-items: center;
}

.jcc {
  justify-content: center;
}

.jcsa {
  justify-content: space-around;
}

.jcsb {
  justify-content: space-between;
}




